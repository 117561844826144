body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --gradient-dark-overlay: linear-gradient(180deg, rgba(16, 16, 16, 0) 0%, #101010 93%);
  --gradient-green-teal: linear-gradient(90deg, #7de264 0%, #59beb5 100%);
  --gradient-white-to-pale-green: linear-gradient(90deg, #ffffff 0%, #cedeb3 100%);
  --gradient-semi-transparent-green: linear-gradient(154.2deg, rgba(53, 173, 130, 0.4) -14.45%, rgba(5, 5, 15, 0.008) 55.4%);
  --color-black-1000: #101010;
  --color-green: #7de264;
  --color-dark-green: #2dc008;
  --color-teal: #59beb5;
  --color-light-green: #b9ff984d;
  --color-sky-blue: #49afdb;
  --color-cyan: #6bfff7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
