.main-subscription-container {
  display: flex;
  gap: 1.25rem;
  width: 100%;
}

.subscription-card {
  background-color: var( --color-white);
  display: flex;
  flex-direction: column;
  border-radius: 0.75rem;
  padding: 1rem;
  width: 100%;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.card-header h4 {
  font-family: var(--default-font-family-Syne);
  font-size: var(--default-font-size-18);
  line-height: 1.313rem;
  font-weight: 600;
}

.active-subscription-btn {
  background-color: #62c7a321;
  color: #5bc0b3;
  border-radius: 1rem;
  padding: 0.25rem 0.75rem;
  font-size: 0.75rem;
  line-height: 18px;
  text-align: center;
  font-weight: 700;
  font-family: var(--default-font-family-Dm-Sans);
}

.card-content {
  padding: 1rem;
}

.card-content-item {
  display: flex;
  padding: 0.75rem 0;
  width: 100%;
  align-items: center;
  gap: 1.25rem;
  border-bottom: 1px solid #f0f0f0;
}

.card-content-item span {
  font-size: var(--default-font-size-14);
  line-height: 21px;
  font-weight: 400;
  font-family: var(--default-font-family-Dm-Sans);
  color: var(--color-gray-600);
}

.card-content-item span:first-child {
  width: 150px;
  text-align: left;
}

.card-content-item .value {
  flex: 1;
  text-align: left;
  color: var(--color-black);
}

.card-content-item:last-child {
  border-bottom: none;
}

.subscriptions-button-section {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 20px 0;
}

.cancel-btn {
  background-color: var(--color-white);
  color: #f2295b;
  font-size: 12px;
  font-weight: 500;
  border: 1px solid #f2295b;
  padding: 0 12px;
}

.change-plan-btn {
  font-size: 12px;
  font-weight: 500;
}

.payment-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid var(--color-grey-300);
}

.payment-card:last-child {
  border-bottom: none;
}

.add-new-btn {
  font-family: var(--default-font-family);
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  border-radius: 8px;
}

.payment-details {
  display: flex;
  align-items: center;
  gap: 15px;
}

.payment-content {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.payment-content p:nth-child(1) {
  font-weight: 700;
  font-size: var(--default-font-size-14);
  line-height: 21px;
  font-family: var(--default-font-family-Dm-Sans);
  margin-top: 12px;
  margin-bottom: 0;
}

.payment-content p:nth-child(2) {
  font-weight: 400;
  font-size: var(--default-font-size-14);
  line-height: 21px;
  font-family: var(--default-font-family-Dm-Sans);
  color: var(--color-gray-600);
}

.payment-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.payment-actions button {
  margin-left: 8px;
}

.primary-card-btn {
  background-color: var(--color-light-green);
  color: var(--color-black);
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  font-weight: 600;
  font-family: var(--default-font-family);
}

.transparent-btn {
  background-color: transparent;
  color: var(--color-black);
  border: 1px solid var(--color-gray-200);
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  font-weight: 600;
  font-family: var(--default-font-family);
}

.recent-history-container {
  margin-top: 2rem;
  background-color: var(--color-white);
  border-radius: 0.75rem;
  padding: 2rem;
  width: 100%;
}

.recent-history-container h4 {
  margin-bottom: 1rem;
  font-family: var(--default-font-family-Syne);
  font-weight: 600;
  font-size: var(--default-font-size-18);
  color: var(--color-black);
}

.action-donwload-button {
  border: 1px solid var(--color-gray-200);
  background-color: var(--color-white);
  padding: 0.4rem 0.5rem;
  border-radius: 4px;
  cursor: pointer;
}
