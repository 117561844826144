.dashboard-organization-container {
  box-sizing: border-box;
  margin: auto;
}

.organization-dashboard-sub-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.dashboard-organization-heading {
  font-family: var(--default-font-family-Inter);
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5rem;
  text-align: left;
  color: var(--color-gray-900);
}

.dashboard-organization-heading-gray {
  font-family: var(--default-font-family-Inter);
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5rem;
  text-align: left;
  color: #6b7280;
}

.organization-member-cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  gap: 1rem;
  margin-top: 1.5rem;
}

.dashboard-organization-table-container {
  padding: 1.5rem;
  margin-top: 1.5rem;
  background-color: var(--color-white);
  border-radius: 12px;
}

.dashboard-organization-table-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard-organization-table-header-container {
  font-family: var(--default-font-family-Inter);
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 24.2px;
  text-align: left;
  color: var(--color-gray-900);
}

.dashboard-organization-table-header-container button {
  color: var(--color-white);
}

.dashboard-organization-member-info {
  display: flex;
  align-items: center;
}

.member-info-avatar {
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 10px;
  background-color: var(--color-dark-green);
}

.dashboard-organization-view-link {
  color: var(--color-dark-green);
  text-decoration: underline;
  font-family: var(--default-font-family-Dm-Sans);
  font-size: var(--default-font-size-14);
  font-weight: 400;
  line-height: 21px;
  text-align: left;
}

.table-action-icons {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.action-icon {
  cursor: pointer;
}

.search-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
}

.search-input-container {
  background-color: var(--color-white);
  padding: 0px 12px;
  border-radius: 8px;
  cursor: pointer;
  min-width: 174px;
  height: 37px;
  border: none;
  outline: none;
}

.search-input {
  margin-top: 4px;
  margin-left: 8px;
  border: none;
  outline: none;
}

.search-icon{
  margin-bottom: 2px;
}
