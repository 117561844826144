.card-headers-container {
    display: flex;
    justify-content: space-evenly;
    gap: 1rem;
}

.card-headers-container div:nth-child(4) {
    width: 40px;
}

.single-card-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    justify-content: center;
    border: 2px solid #E0E0E0;
    border-radius: 6px;
    padding: 10px 12px;
    height: 74px;
    width: 100%;
    box-shadow: 0px 2px 4px 0px #00000012;
    cursor: pointer;
    transition: border 0.3s, color 0.3s;
}

.single-card-section .card-text,
.single-card-section .card-dots {
    font-family: var(--default-font-family);
    font-size: 13px;
    font-weight: 600;
    color: #727F96;
    transition: color 0.3s;
}

.single-card-section .card-dots {
    margin-bottom: 3rem;
}

.single-card-section.active-card {
    border-color: #0570DE;
}

.single-card-section.active-card .card-text,
.single-card-section.active-card .card-dots {
    color: #0570DE;
}

.card-number-container {
    margin-top: 1rem;
}

.card-number-container label {
    color: #4F5B76;
    font-weight: 600;
    font-family: var(--default-font-family);
}

.input-with-icons {
    position: relative;
    display: flex;
    align-items: center;
    width: 432px;
    margin-top: 4px;
}

.card-number-field {
    border: 2px solid #e0e0e0;
    border-radius: 6px;
    box-shadow: 0px 2px 4px 0px #00000012;
}

.card-number-groups-images {
    position: absolute;
    right: 10px;
    display: flex;
    gap: 5px;
}

.visa-icon-small {
    width: 24px;
    height: 16px;
}

.input-field-container {
    margin-top: 1rem;
    display: flex;
    gap: 0.5rem;
}

.input-field-container label {
    color: #4F5B76;
    font-weight: 600;
    font-family: var(--default-font-family);
}

.fields-entry {
    border: 2px solid #e0e0e0;
    border-radius: 6px;
    box-shadow: 0px 2px 4px 0px #00000012;
    margin-top: 4px;
}

.subscribe-button-section {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
}

.subscribe-and-pay {
    background-color: #635BFF;
    border: 1px solid #635BFF;
    font-weight: 600;
    font-size: var(--default-font-size-14);
    line-height: 20px;
    text-align: center;
    color: var(--color-white);
    border-radius: 4px;
    width: 428px;
    padding: 8px 16px;
}

.country-dropdown {
    border: 2px solid #e0e0e0;
    box-shadow: 0px 2px 4px 0px #00000012;
    margin-top: 4px;
    padding: 26px 16px !important;
    width: 214px;
    color: var(--color-black);
}

.selected-value-options .drop-down-selected-option{
    font-weight: 600;
}