.change-password-container {
    background: var(--color-white);
    border-radius: 12px;
    padding: 24px;
    width: 100%;
}

.change-password-title {
    font-family: var(--default-font-family-Inter);
    line-height: 24.2px;
    color: var(--color-gray-900);
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 20px;
}

.change-password-fields {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
}
.change-password-fields label {
    color: var(--color-gray-500);
    line-height: 21px;
    font-weight: 400;
    font-size: var(--default-font-size-14);
    font-family: var(--default-font-family-DM-Sans);
}

.password-feilds-inputs {
    border: 1px solid var(--color-gray-200);
    border-radius: 8px;
    margin-top: 10px;
    width: 374px;
}

.password-feilds-inputs ::placeholder {
    font-family: var(--default-font-family-DM-Sans);
    color: var(--color-grey-400);
    font-weight: 400;
    font-size: var(--default-font-size-16);
    padding: 0px;
}

.change-password-confirm-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 1.5rem;
}

.password-cancel-button {
    border: 1px solid #F2295B;
    border-radius: 8px;
    background-color: var(--color-white);
    color: #F2295B;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    font-family: var(--default-font-family);
}

.save-changes-button {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    font-family: var(--default-font-family);

}