.button {
  font-family: var(--default-font-family);
  display: inline-block;
  padding: 0.6rem 1.25rem;
  font-size: var(--default-font-size-16);
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  border: none;
  border-radius: 8px;
  cursor: pointer !important;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.button:disabled {
  background-color: #3a3f47;
  color: #c1c7cd;
  cursor: not-allowed;
  box-shadow: none;
}

.button--full-width {
  width: 100%;
}

.button--primary {
  background: var(--gradient-green-teal);
  color: var(--color-black);
}

.button--primary img {
  margin-left: 6px;
}

.button--secondary {
  background-color: #49afdb;
  color: #ffffff;
}

.button--transparent {
  background-color: transparent;
  color: var(--color-grey-400);
  border: 1px solid var(--color-grey-400);
}

.button--outline {
  background-color: transparent;
  color: var(--color-dark-green);
  border: 1px solid var(--color-dark-green);
  cursor: default !important;
}

.button--square {
  border-radius: 0;
}
