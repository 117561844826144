.home-layout-container {
  position: relative;
  z-index: 1;
  padding: 24px;
  overflow: hidden;
}

.home-layout-content {
  background-color: #ffff;
  border-radius: 12px;
  overflow: hidden;
}
.home-layout-container::before {
  content: '';
  position: absolute;
  top: -24px;
  left: -24px;
  right: -24px;
  bottom: 0px;
  border-radius: 24px;
  background: linear-gradient(90deg, #7de264, #59beb5);
  z-index: -1;
  padding: 24px;
}