.dashboard-navigation {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  min-width: 264px;
  box-sizing: border-box;
  padding: 1rem;
  position: relative;
  height: 100%;
}

.dashboard-navigation-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
  height: 4rem;
  gap: 0.5rem;
  margin-bottom: 2rem;
}


.dashboard-navigation-logo-container img {
  height: 130%;
}

.dashboard-navigation-text-logo-container p {
  font-weight: 600;
  margin: 0;
  font-size: 1.5rem;
}


.dashboard-navigation-list-items {
  height: 100%;
  flex: 1;
}

.dashboard-navigation-list-items {
  font-family: var(--default-font-family-Dm-Sans);
  color: var(--color-gray-900);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 0.2rem 0.1rem;
  text-align: left;
}
