.current-plan-container {
    background-color: var(--color-gray-50);
    padding: 24px;
    border-radius: 8px;
    gap: 3rem;
}

.current-plan-section {
    width: 100%;
}

.current-plan-section {
    font-size: var(--default-font-size-18);
    line-height: 27px;
    font-weight: 400;
    font-family: var(--default-font-family-Dm-Sans);
}

.current-plan-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.other-plans-container {
    margin-top: 2rem;
}

.other-plans-container p:nth-child(1) {
    font-family: var(--default-font-family-Dm-Sans);
    font-size: var(--default-font-size-18);
    color: var(--color-black);
    line-height: 27px;
    font-weight: 400;
}

.other-plans-container p:nth-child(2) {
    font-family: var(--default-font-family-Dm-Sans);
    font-size: var(--default-font-size-16);
    color: var(--color-black);
    line-height: 27px;
    font-weight: 400;
}

.plans-card-section {
    background-color: var(--color-gray-50);
    padding: 16px 24px;
    border-radius: 8px;
    margin-bottom: 12px;
    gap: 3rem;
    width: 418px;
}

.plans-card-content {
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
}

.plan-buttons {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.switch-plan-button {
    color: var(--color-black);
    border: 1px solid var(--color-grey-300);
    font-weight: 700;
    font-size: var(--default-font-size-16);
    line-height: 24px;
    font-family: var(--default-font-family-Dm-Sans);
}

.view-feature-button {
    background-color: transparent;
    font-weight: 700;
    font-size: var(--default-font-size-16);
    line-height: 24px;
    font-family: var(--default-font-family-Dm-Sans);
}