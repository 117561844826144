.slider-container {
  max-width: 48rem;
  margin: 4rem auto;
}

.carousel-slide {
  text-align: center;
  position: relative;
  cursor: pointer;
}

.carousel-label {
  font-family: Syne;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;
  transition: background 0.3s ease, -webkit-text-fill-color 0.3s ease;
}

.carousel-label.active {
  background: linear-gradient(90deg, #7de264 0%, #59beb5 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.carousel-label.inactive {
  color: gray;
}

.carousel-content {
  max-width: 568px;
  width: 100%;
  min-height: 200px;
  margin-top: 1rem;
  font-family: var(--default-font-family-Dm-Sans);
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  margin: 3rem auto;
}

.carousel-content.active {
  display: block;
}

.carousel-content.inactive {
  display: none;
}
