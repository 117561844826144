.footer {
  padding: 80px;
  gap: 65px;
  background: linear-gradient(154.2deg, rgba(53, 173, 130, 0.4) -14.45%, rgba(5, 5, 15, 0.008) 55.4%);
  margin: auto;
  color: var(--color-white);
}

.footer-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-section {
  flex: 1;
  margin: 10px;
  min-width: 200px;
}

.logo-section {
  max-width: 400px;
}

.footer-logo {
  width: 150px;
  margin-bottom: 20px;
}

.footer-text {
  margin-bottom: 20px;
  max-width: 317px;
  font-family: var(--default-font-family-Dm-Sans);
  font-size: var(--default-font-size-16);
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.newsletter form {
  display: flex;
  align-items: center;
  gap: 10px;
}
.newsletter input {
  color: #ffffff;
  font-family: var(--default-font-family-Dm-Sans);
  min-width: 233px;
  font-size: var(--default-font-size-16);
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  background: linear-gradient(95.55deg, #1d1d21 18.01%, rgba(29, 29, 33, 0.17) 52.48%, rgba(29, 29, 33, 0.96875) 92.15%);
}
.footer-text-field {
  max-width: 235px;
  border: 1px solid var(--color-gray-500);
  border-radius: 8px;
  background: linear-gradient(95.55deg, #1d1d21 18.01%, rgba(29, 29, 33, 0.17) 52.48%, rgba(29, 29, 33, 0.96875) 92.15%);
  color: #ffffff;
}

.subscribe-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #3bc177;
  color: #000;
  cursor: pointer;
}

.footer-social-icons {
  margin-top: 2rem;
  display: flex;
  gap: 10px;
}

.footer-social-icons img {
  height: 38px;
  width: 38px;
}

.links-section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 923px;
}

.footer-links {
  flex: 1;
  margin: 0 10px;
}

.footer-links h3 {
  margin-bottom: 20px;
  font-family: var(--default-font-family-Syne);
  font-size: var(--default-font-size-18);
  font-weight: 600;
  line-height: 21.6px;
}

.footer-links ul {
  list-style: none;
  padding: 0;
}

.footer-links li {
  margin-bottom: 10px;
}

.footer-pages-links {
  color: var(--color-grey-300);
  font-family: var(--default-font-family-Dm-Sans);
  font-size: var(--default-font-size-16);
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  text-decoration: none;
}

.footer-pages-links.active {
  color: var(--color-green);
}

.footer-links a {
  text-decoration: none;
  color: var(--color-grey-300);
  color: var(--color-grey-300);
  font-family: var(--default-font-family-Dm-Sans);
  font-size: var(--default-font-size-16);
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  text-decoration: none;
}

.footer-links a:hover {
  color: #3bc177;
}

.footer-bottom {
  text-align: center;
  margin-top: 40px;
  border-top: 1px solid var(--color-green);
  padding-top: 20px;
  opacity: 20%;
}

.copy-right {
  font-family: var(--default-font-family-Dm-Sans);
  font-size: var(--default-font-size-16);
  font-weight: 400;
  line-height: 24px;
  color: #d0d5dd;
  opacity: 1;
  text-align: center;
}

.newsletter button {
  padding: 1rem;
  height: 100%;
}

@media (max-width: 900px) {
  .newsletter form {
    flex-wrap: wrap;
  }
}
@media (max-width: 768px) {
  .footer {
    padding: 80px 4%;
  }
  .newsletter input {
    min-width: 200px;
    width: 100%;
  }
}
