.nav-bar-list-item {
  display: flex;
  padding: 0.75rem 0 0.75rem 1.2rem;
  gap: 0.8rem;
  align-items: center;
  border-radius: 7px;
  margin: 0.05rem 0;
  position: relative;
  color: var(--color-gray-900);
  text-decoration: none;
}

.nav-bar-list-item p {
  margin: 0;
  font-family: var(--default-font-family-Dm-Sans);
  color: inherit;
}

.nav-bar-list-item.active {
  background: var(--color-gray-800);
}

.nav-bar-list-item.active p {
  color: var(--color-white);
}

.nav-bar-list-item.active svg path {
  stroke: var(--color-white);
}

.nav-bar-list-item a {
  text-decoration: none;
  color: inherit;
}

.navbar-list-element {
  list-style-type: none;
}
