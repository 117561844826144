.meet-jurist-team-section {
  background-image: url("../../../../assets/images/backgrounds/backgroun-square-lines.png");
  background-repeat: no-repeat;
  background-size: 100% 95%;
  padding: 112px auto;
}

.team-section {
  padding: 40px;
  color: #fff;
  max-width: 1216px;
  margin: auto;
}

.team-section h2 {
  margin-bottom: 20px;
  font-family: Syne;
  font-size: 48px;
  font-weight: 600;
  line-height: 74px;
  text-align: left;
  margin-top: 12rem;
}

.team-section p {
  font-family: DM Sans;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;

  margin-bottom: 40px;
}

.team-members {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  max-width: 1216px;
}

.team-hiring-main-container {
  display: flex;
  gap: 10px;
}

@media (max-width: 1000px) {
  .team-hiring-main-container {
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
  }
}
