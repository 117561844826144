.additional-info {
  padding: 15px;
  border-radius: 8px;
  flex: 1;
  max-width: 476px;
  min-height: 616.57px;
  padding: 30px;
  gap: 30px;
  border-radius: 12px;
  border: 1px solid #7de26433;
}

.additional-info h4 {
  font-family: Syne;
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
  text-align: left;
}

.additional-info h6 {
  font-family: Syne;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;
  text-align: left;
}

.additional-info p {
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.additional-info-img {
  border-radius: 8px;
  margin-bottom: 2rem;
}

.social-accounts-chief {
  display: flex;
  gap: 1rem;
  align-items: center;
}

@media (max-width: 568px) {
  .additional-info-img {
    max-width: 100%;
  }
}
