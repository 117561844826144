.voices-of-success-container {
  text-align: center;
  padding: 1rem;
}

.voices-of-success--container {
  max-width: 1216px;
  margin: auto;

  overflow: hidden;
}
.voices-of-success-title {
  font-size: 24px;
  margin-bottom: 20px;
  font-family: var(--default-font-family-Dm-Sans);
  font-size: 48px;
  font-weight: 600;
  line-height: 74px;
  text-align: left;
  color: #fff;
}

.voices-of-success-slide {
  background: #fff;
  color: #333;
  padding: 80px;
  position: relative;
  display: inline-block;
  max-width: 1216px;
  min-height: 494px;
  margin: 0 auto;
  cursor: pointer;
}
.voices-of-success-border {
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;
}
.voices-of-success-sub-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
  flex-wrap: wrap;
}

.voices-of-success-sub-container p {
  font-family: var(--default-font-family-Dm-Sans);
  font-size: 40px;
  font-weight: 500;
  line-height: 60px;
  text-align: left;
  color: var(--color-gray-500);
}

.voices-of-success-description {
  max-width: 785px;
  gap: 32px;
}

.voices-of-success-author {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.voices-of-success-author img {
  border-radius: 8.8px;
  margin-right: 10px;
  height: 80px;
  width: 80px;
}

.voices-of-success-author div {
  text-align: left;
}

.voices-of-success-author strong {
  display: block;
  font-family: var(--default-font-family-Syne);
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
  text-align: left;
}

.voices-of-success-author span {
  font-size: 0.9em;
  color: #8b8d98;
  font-family: var(--default-font-family-Dm-Sans);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}

.slick-dots {
  position: absolute;
  bottom: 15%;
  right: 18%;
  display: flex;
  display: flex !important;
  justify-content: center;
  gap: 6px;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  margin: 0;
}

.slick-dots li button:before {
  font-size: 0;
  height: 10px;
  width: 10px;
  background-color: var(--color-gray-500);
  border-radius: 50%;
}

.slick-dots li.slick-active button:before {
  width: 20px !important;
  height: 10px;
  background-color: var(--color-green);
  margin: 0;
  border-radius: 100px;
}

@media (max-width: 786px) {
  .voices-of-success-slide {
    padding: 5rem 0.7rem;
  }

  .slick-dots {
    left: -15px;
    bottom: 25px;
  }
}
