.software-suites-section {
  padding: 2rem;
  color: #fff;
}

.software-suites-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  max-width: 76rem;
  margin: 0 auto;
}

.software-suites-title {
  font-family: var(--default-font-family-Syne);
  font-size: 48px;
  font-weight: 600;
  line-height: 74px;
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.software-suites-sub-title {
  max-width: 729px;
  gap: 16px;
  margin: 5rem auto 2rem;
  font-family: var(--default-font-family-Syne);
  font-size: 48px;
  font-weight: 600;
  line-height: 74px;
  text-align: center;
  margin-top: 10rem;
}

.software-suites-description {
  max-width: 800px;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  margin: auto;
}

.features-carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

.feature {
  text-align: center;
  padding: 20px;
  font-size: 1.2em;
  color: #333;
  transition: color 0.3s ease;
}

.active-feature {
  color: #007bff;
}

.feature-component {
  display: flex;
  justify-content: center;
  padding: 20px;
  font-size: 1em;
}
