.order-summary-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var( --color-gray-50);
    padding: 16px 24px;
    border-radius: 8px;
    margin-bottom: 12px;
    gap: 3rem;
}

.member-name {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    font-family: var(--default-font-family-Dm-Sans);
}

.member-email {
    font-size: var(--default-font-size-14);
    color: #2dc008;
    font-family: var(--default-font-family-Dm-Sans);
    font-weight: 400;
    border-bottom: 1px solid #2dc008;
}

.member-position {
    font-size: var(--default-font-size-14);
    font-weight: 400;
}

.small-firms-section {
    width: 100%;
}

.small-firms-section h3 {
    font-size: var(--default-font-size-18);
    line-height: 27px;
    font-weight: 400;
    font-family: var(--default-font-family-Dm-Sans);
    margin-bottom: 2rem;
}

.key-value-pair {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.key {
    font-family: var(--default-font-family-Dm-Sans);
    font-size: var(--default-font-size-14);
    font-weight: 400;
    flex: 1;
    text-align: left;
    color: var(--color-gray-600);
}

.value {
    font-family: var(--default-font-family-Dm-Sans);
    font-size: var(--default-font-size-14);
    font-weight: 400;
    flex: 1;
    text-align: left;
    color: var(--color-black);
}

.checkout-button {
    width: 100%;
    padding: 12px;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    margin-top: 16px;
}

.border-bottom {
    color: var( --color-gray-100);
}