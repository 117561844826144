.card--container {
  border: 1px solid var(--color-gray-100);
  border-radius: 12px;
  overflow: hidden;
  max-width: 360px;
  width: 100%;
  text-align: left;
  margin: 20px auto;
  padding: 20px 20px 17px 20px;
}

.card--image {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.card--content {
  padding: 20px 1px 10px 1px;
}

.card--date {
  font-family: var(--default-font-family-Dm-Sans);
  font-size: var(--default-font-size-14);
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  margin-bottom: 10px;
  color: var(--color-sky-blue);
}

.card--title {
  font-family: var(--default-font-family-Syne);
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 28.8px;
  text-align: left;
  color: var(--color-white);
}

.card--description {
  font-family: var(--default-font-family-Dm-Sans);
  font-size: var(--default-font-size-16);
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  padding-bottom: 20px;
  color: var(--color-gray-200);
  margin-bottom: 1.3rem;
}

.card--description::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background: repeating-linear-gradient(to right, var(--color-gray-200), var(--color-gray-200) 4px, transparent 4px, transparent 8px);
  position: absolute;
  bottom: -2rem;
  left: 0;
  position: relative;
}

.card--link {
  font-family: var(--default-font-family-Dm-Sans);
  font-size: var(--default-font-size-14);
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  background: linear-gradient(90deg, #7de264 0%, #59beb5 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
  padding: 0%;
}

.card--link:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .card-container {
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .card--container {
    padding: 10px;
  }

  .card--title {
    font-size: var(--default-font-size-18);
  }

  .card--description {
    font-size: 14px;
  }
}
