.confirm-account-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30rem;
    flex-direction: column;
  }
  
  .confirm-account-page button {
    font-size: 2rem;
    margin-bottom: 1rem;
    margin: 3rem 0;
  }
  