.dropdown-container {
  position: relative;
  display: flex;
  align-items: center;
  background-color: var(--color-white);
  padding: 0px 12px;
  border-radius: 8px;
  cursor: pointer;
  min-width: 184px;
  height: 37px;
}

.dropdown-label {
  display: flex;
  align-items: center;
}

.dropdown-icon {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 8px;
}

.dropdown-arrow {
  width: 0.6rem;
  height: 0.4rem;
  margin-left: auto;
  transition: transform 0.3s ease;
}

.dropdown-arrow.open {
  transform: rotate(180deg);
}

.dropdown--menu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: var(--color-white);
  border: 1px solid #d1d5db;
  border-radius: 8px;
  margin-top: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.dropdown--item {
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 8px;
  font-family: var(--default-font-family-Dm-Sans);
  font-size: var(--default-font-size-14);
  font-weight: 400;
  line-height: 21px;
  text-align: left;
}

.dropdown--item:hover {
  background-color: #f1f5f9;
}

.drop-down-selected-option {
  font-family: var(--default-font-family-Dm-Sans);
  font-size: var(--default-font-size-14);
  font-weight: 400;
  line-height: 21px;
  text-align: left;
}