.founding-engineer-section {
  padding: 20px;
  border-radius: 10px;
  max-width: 716px;
  margin: auto;
  border: 1px solid var(--color-green-20);
}

.team-images {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  gap: 5px;
}

.team-images img {
  border-radius: 50%;
  width: 44px;
  height: 44px;
  border: 1.53px solid var(--color-green);
  position: relative;
  z-index: 1;
  background-color: #49afdb;
  margin-left: -16px;
}

.team-images img:first-of-type,
.team-images img:nth-of-type(n + 2):nth-of-type(4n + 1) {
  margin-left: 0;
}

.team-job-title {
  font-family: Syne;
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
  text-align: left;
  margin-bottom: 12px;
  margin-top: 1.2rem;
}

.team-job-title-description {
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  max-width: 636px;
  margin-bottom: 30px;
}

.essentials {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  text-align: left;
  margin-bottom: 30px;
}

.essential-item {
  display: flex;
  align-items: flex-start;
  max-width: 288px;
  padding-bottom: 10px;
  gap: 10px;
  border-bottom: 1px solid #7de26433;
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #b9ff98;
}

.check-icon {
  color: #b9ff98;
  margin-right: 10px;
}

@media (max-width: 786px) {
  .apply-btn-mobile {
    width: 100%;
  }
}
