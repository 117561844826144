.data-application-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 20px;
  color: #fff;
  padding-top: 8rem;
}

.data-application-section h2 {
  font-size: 2rem;
  max-width: 668px;
  margin: auto;
  margin-bottom: 1rem;
  font-family: Syne;
  font-size: 48px;
  font-weight: 600;
  line-height: 74px;
  text-align: left;
}

.data-application-section-subheading {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  max-width: 752px;
  margin: auto;
  font-family: Archivo;
  font-size: 18px;
  font-weight: 300;
  line-height: 27px;
  text-align: center;
  color: var(--color-gray-100);
}

.key-features-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 610px;
}

.key-features-container img {
  width: 1rem;
  height: 0.875rem;
  margin-top: 0.3rem;
}

.key-features-container span {
  max-width: 581px;
  font-family: DM Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: var(--color-gray-100);
}

.key-features-sub-container {
  display: flex;
  gap: 20px;
  align-items: flex-start;
  border-bottom: 1px solid var(--color-gray-600);
  padding: 6px 0px 12px 0px;
}

.data-application-section-accordions-container {
  display: flex;
  align-items: flex-start;
  max-width: 1216px;
  margin: 3rem auto;
  gap: 10rem;
}

@media (max-width: 768px) {
  .data-application-section {
    padding: 10px;
  }

  .data-application-section h2 {
    font-size: 1.5rem;
  }

  .data-application-section p {
    font-size: 1rem;
  }

  .data-application-section-accordions-container {
    flex-wrap: wrap;
  }
}
