.hero-section-bg-page-bg {
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
}

.hero-section-bg-page-bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("./../../../assets/images/hero/hero-section-background.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;
}

.hero-section-bg-page-bg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("./../../../assets/images/hero/Light Gradient 17.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 2;
  opacity: 0.8;
  transform: rotate(180deg);
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(16, 16, 16, 0.88) 0%, rgba(16, 16, 16, 0.88) 30%, rgba(16, 16, 16, 0.4) 60%, rgba(16, 16, 16, 1) 80%, rgba(16, 16, 16, 1) 100%);
  z-index: 3;
  opacity: 0.95;
}

.content {
  position: relative;
  z-index: 4;
}

.recommendations-container {
  background-color: var(--color-black);
}

.recommendations-container-bg {
  background-color: var(--color-black);
  background-image: url("./../../../assets/images/icons/recommendation-vector-background.png"), url("./../../../assets/images/icons/linear-gradient-circle.png");
  background-repeat: no-repeat, no-repeat;
  background-size: 50% 43%, auto;
  background-position: top left, center right;
}

.home-voices-of-success-bg-color {
  background-color: var(--color-black);
}
.home-voices-of-success-bg-image {
  background-image: url("./../../../assets/images/backgrounds/circle-lines.png"), url("./../../../assets/images/backgrounds/circle-lines-with-pink-bg.png");
  background-repeat: no-repeat;
  background-size: 50% 30%, 70% 60%;
  background-position: top 18% left, top 40% right;
}
