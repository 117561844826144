.insights-container {
  text-align: center;
  color: white;
  padding: 60px 20px;
}

.insights-container-heading {
  font-family: var(--default-font-family-Syne);
  font-size: 3rem;
  font-weight: 600;
  line-height: 74px;
  text-align: center;
}

.insights-container-description {
  font-family: var(--default-font-family);
  font-size: var(--default-font-size-18);
  font-weight: 300;
  line-height: 27px;
  text-align: center;
  color: #f2f4f7;
  max-width: 752px;
  margin: 1rem auto 60px auto;
}

.insights-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  max-width: 1120px;
  margin: auto;
}
