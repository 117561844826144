.dashboard-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(335px, 1fr));
  gap: 1rem;
}

.dashboard-card {
  position: relative;
  background-color: white;
  border-radius: 2px 12px 12px 2px;
  padding: 24px 38px 24px 38px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 203px;
  overflow: hidden;
}
.dashboard-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 14px;
  height: 100%;
  background: var(--gradient-color);
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.dashboard-card .icon-title-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  z-index: 1;
}

.dashboard-card .icon-container {
  padding: 0.5rem;
}
.title-container {
  display: flex;
  flex-direction: column;
  gap: 10px; 
  align-items: flex-start;
}

.dashboard-card .title {
  font-size: 24px;
  font-weight: 600;
  color: #101828;
  line-height: 28.8px;
  font-family: var(--default-font-family-Syne);
}

.dashboard-card .badge-container {
  background-color: #b9ff98;
  color: #0f4900;
  padding: 8px;
  border-radius: 24px;
  font-weight: 400;
  line-height: 15px;
  font-size: 10px;
  font-family: var(--default-font-family-Dm-Sans);
  cursor: default !important;
}

.dashboard-pannel-link {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  text-decoration: none;
}

.dashboard-pannel-link .bottom-text-practice-area {
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #1d2939;
  font-family: var(--default-font-family-Dm-Sans);
  border-bottom: 1px solid #1d2939;
}

.dashboard-pannel-link img {
  margin-bottom: 8px;
}
