.main-container-account-option {
    display: flex;
    gap: 1.5rem;
}

.account-options-title {
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
    font-family: var(--default-font-family-Inter);
    font-weight: 500;
    line-height: 24px;
}

.left-side-cards {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.account-card-containers {
    background-color: var(--color-white);
    padding: 1.5rem;
    border-radius: 12px;
}

.account-card-containers h2 {
    font-size: var(--default-font-size-18);
    font-weight: 600;
    line-height: 21.6px;
    font-family: var(--default-font-family-Syne);
    margin-bottom: 1rem;
    color: var(--color-black);
    margin-top: 0.8rem;
}

.profile-content-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profile-content-main img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
}

.profile-content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.6rem;
}

.profile-content .profile-content-upload-button {
    font-family: var(--default-font-family);
    background-color: transparent;
    border: 1px solid var(--color-gray-200);
    padding-right: 20px;
    text-align: center;
    padding-left: 20px;
    line-height: 18px;
    font-weight: 600;
    font-size: var(--default-font-size-14);
}

.profile-content .account-delete-icon {
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.buttons-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.buttons-container .setting-privacy-section-buttons {
    font-family: var(--default-font-family);
    background-color: transparent;
    border: 1px solid var(--color-gray-200);
    padding-right: 20px;
    text-align: left;
    padding-left: 20px;
    line-height: 18px;
    font-weight: 600;
    font-size: var(--default-font-size-14);
}

.buttons-container .setting-privacy-section-buttons img {
    margin-left: 0.5rem;
}

.personal-information-container {
    flex: 2;
    background-color: var(--color-white);
    padding: 1.5rem;
    border-radius: 12px;
}

.personal-information-container h1 {
    font-size: var(--default-font-size-18);
    font-weight: 600;
    line-height: 21.6px;
    font-family: var(--default-font-family-Syne);
    margin-bottom: 1rem;
    color: var(--color-black);
}

.personal-information-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.input-fields-container {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}

.input-fields-container label {
    flex: 1;
    display: flex;
    flex-direction: column;
    color: var(--color-gray-500);
    font-family: var(--default-font-family-DM-Sans);
    font-size: var(--default-font-size-14);
    font-weight: 400;
    gap: 10px;
}

.text-fields-account-sections {
    border: 1px solid var(--color-gray-200);
    border-radius: 8px;
}

.text-fields-account-sections ::placeholder {
    color: var(--color-grey-400);
}

.account-type-dropdown{
    border: 1px solid var(--color-gray-200);
    padding: 1.7rem;
    color: var(--color-black);
}