/* Recommendation.css */
.recommendation-container {
  font-family: var(--default-font-family-Dm-Sans);
  padding: 20px;
  color: var(--color-white);
}

.recommendation-header {
  text-align: center;
  margin-bottom: 50px;
}

.recommendation-subtitle {
  font-family: var(--default-font-family-Syne);
  color: var(--color-dark-green);
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;
  margin-bottom: 1.5rem;
}

.recommendation-subtitle-card {
  font-family: var(--default-font-family-Syne);
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;
  margin-bottom: 2.8rem;
  margin-top: 1.5rem;
}

.recommendation-title {
  font-family: var(--default-font-family-Syne);
  font-size: 48px;
  font-weight: 600;
  line-height: 74px;
  text-align: center;
  margin: 20px auto;
  max-width: 938px;
  margin-bottom: 1.5rem;
}

.recommendation-description {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  max-width: 752px;
  margin: 0 auto;
}

.recommendation-section {
  display: flex;
  justify-content: center;
  margin: 50px 0;
}

.recommendation-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
  background-color: var(--color-white);
  color: var(--color-black);
  border-radius: 10px;
  padding: 20px;
  margin: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 76rem;
}
.trial-arrow {
  margin-left: 0.5rem;
}
.recommendation-image {
  width: 200px;
  height: auto;
  margin-right: 20px;
}

.recommendation-content {
  max-width: 590px;
}

.recommendation-content p {
  margin-bottom: 1.5rem;
}
.recommendation-link {
  color: var(--color-black);
  text-decoration: underline;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  cursor: default;
}

.recommendation-footer {
  text-align: center;
  margin: 50px auto;
  max-width: 950px;
}

.recommendation-stats {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.stat-item {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.stat-item h3 {
  font-family: var(--default-font-family-Syne);
  font-size: 36px;
  margin: 0;
}

.stat-item p {
  margin: 0;
}

.recommendation-footer-description {
  max-width: 925px;
  margin: 80px auto;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
}

.atticus-recommends {
  max-width: 488px;
  padding: 24px 2.5rem;
  border-radius: 16px;
  background-color: var(--color-gray-100);
}

.atticus-recommends-content {
  display: flex;
  flex-direction: column;
  padding: 24px 12px;
  gap: 10px;
  border-radius: 8px;
  background-color: var(--color-white);
  margin: 0.5rem;
  box-shadow: 21.4px 21.4px 57.08px 0px #132d611a;
}

.data-driven {
  display: flex;
  gap: 15px;
  align-items: center;
}
.data-driven-container {
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 16px;
  border-radius: 8px;
  background-color: var(--color-white);
  margin: 0.5rem;
  box-shadow: 21.4px 21.4px 57.08px 0px #132d611a;
}

.card-image-wrapper-end {
  display: flex;
  justify-content: end;
}

.atticus-recommends-subtitle {
  font-size: 12px;
  font-weight: 500;
  line-height: 15.62px;
  text-align: left;
}

.data-driven-section {
  position: relative;
  max-width: 488px;
  min-height: 392px;
  padding: 24px 40px;
  border-radius: 16px;
  background-color: var(--color-gray-100);
  display: flex;
  justify-content: center;
  align-items: center;
}

.data-driven-section-image {
  position: absolute;
  top: 8%;
  right: 20px;
}

.data-driven-icons-margin {
  margin-left: -4px;
}

.shape-arrow-down {
  height: 10px;
}

.recommends-show-more-btn {
  cursor: default;
}

.show-more-btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  cursor: default;
}

@media (max-width: 768px) {
  .data-driven-section {
    padding: 1.3rem 0.8rem;
  }
  .atticus-recommends {
    padding: 1rem;
  }

  .data-driven-section-image {
    top: 2%;
    right: 5px;
  }
  .data-driven-container {
    padding: 0.7rem;
  }
  .recommendation-stats {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
}
