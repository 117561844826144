.team-member {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  width: 100%;
}

.team-member-info {
  display: flex;
  position: relative;
  align-items: center;
  margin-top: 4rem;
  margin-bottom: 20px;
  max-width: 100%;
  padding: 70px 320px 70px 40px;
  border-radius: 14px;
  background: linear-gradient(90deg, #7de264 0%, #59beb5 100%);
}

.team-member-image {
  position: absolute;
  right: 3%;
  bottom: 0;
  height: auto;
}

.team-member-details {
  max-width: 772px;
  flex: 1;
}

.team-member-details h3 {
  margin-bottom: 10px;
  font-family: Syne;
  font-size: 3rem;
  font-weight: 600;
  line-height: 74px;
  text-align: left;
  color: var(--color-black);
}

.team-member-details h4 {
  color: var(--color-gray-700);
  margin-bottom: 10px;
  font-family: Syne;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.2rem;
  text-align: left;
}

.team-member-details p {
  font-family: DM Sans;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-align: left;
  color: var(--color-gray-800);
  margin-bottom: 10px;
  max-width: 772;
}

.team-member-details span {
  font-family: Syne;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.75rem;
  text-align: left;
  color: var(--color-black);
  margin-top: 2rem;
}

.social-accounts {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 4rem;
}

@media (max-width: 768px) {
  .team-member-info {
    flex-direction: column;
    padding: 20px;
  }

  .team-member-image {
    position: static;
    margin-top: 20px;
    clip-path: inset(0 0 65% 0);
    max-width: 243px;
    overflow: hidden;
    border-radius: 20px;
  }

  .team-member-details h3 {
    font-size: 1.5rem;
    line-height: 2rem;
    margin-top: -20rem;
  }

  .team-member-details h4 {
    font-size: 1rem;
    line-height: 1.2rem;
  }

  .team-member-details p {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .team-member-details span {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
