.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.pagination-button {
    padding: 8px 12px;
    border: none;
    background-color: #fff;
    color: var(--color-gray-500);
    cursor: pointer;
    border-radius: 4px;
    font-family: Poppins;
    font-size: var(--default-font-size-14);
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.10000000149011612px;
    text-align: center;
}

.pagination .pagination-button:first-child {
    border: 1px solid var(--color-grey-300);
}

.pagination .pagination-button:last-child {
    border: 1px solid var(--color-grey-300);
}

.pagination-button.active {
    background: var(--Brand-Dark-Green, #2dc008);
    color: var(--color-white);
}

.pagination-button:disabled {
    background-color: var(--color-grey-300);
    color: var(--color-gray-10);
    /* border-color: #d1d1d1; */
    cursor: not-allowed;
}