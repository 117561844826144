.hero-section {
  position: relative;
  padding: 4rem 2rem;
  color: white;
  text-align: center;
  overflow: hidden;
}

.hero-content {
  position: relative;
  max-width: 75rem;
  margin: auto;
}

.hero-content h1 {
  font-size: 4.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  line-height: 74px;
  text-align: center;
  font-family: var(--default-font-family-Syne);
  background: linear-gradient(90deg, #ffffff 0%, #cedeb3 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-content p {
  font-size: var(--default-font-size-18);
  max-width: 50rem;
  margin: 2rem auto;
  font-size: 18px;
  font-family: var(--default-font-family-Dm-Sans);
  font-weight: 400;
  line-height: 27px;
  text-align: center;
}

.hero-tools {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin: 2rem auto;
  max-width: 75rem;
}

.hero-tools a {
  font-size: var(--default-font-size-16);
  color: var(--color-cyan);
  text-decoration: none;
  padding: 1rem 1rem;
  border-bottom: 1px solid var(--color-green-20);
  min-width: 12.313rem;
}

.hero-tools a:hover {
  border-bottom: 2px solid var(--color-light-green);
}

.hero-tools img {
  margin-right: 0.5rem;
}

.hero-newsletter form {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: auto;
  max-width: 43.75rem;
}

.hero-newsletter input {
  background: linear-gradient(95.55deg, #1d1d21 18.01%, rgba(29, 29, 33, 0.17) 52.48%, rgba(29, 29, 33, 0.96875) 92.15%);
  width: 100%;
  border: 1px solid var(--color-gray-500);
  color: #ffffff;
}

.hero-section-youtube-container {
  margin: 5rem auto;
  max-width: 75rem;
  height: auto;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}
.hero-section-youtube-container iframe {
  height: auto;
  width: 100%;
}

.hero-newsletter button {
  padding: 1rem;
  min-width: 240px;
  width: 100%;
}

.partner-logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem 0;
  max-width: 80rem;
  gap: 20px;
  margin: 3.125rem auto 7rem auto;
}

.partner-logos img {
  margin: 0 0.938rem;
  max-width: 100px;
  flex: 1 1 calc(20% - 20px);
}

.partner-logos span {
  width: 1px;
  height: 3.125rem;
  gap: 0px;
  opacity: 0.7;
  background: #ffffff2b;
}

.text-field-hero-section {
  border: 1px solid var(--color-grey-400);
  border-radius: 8px;
}

@media (min-width: 768px) {
  .hero-section-youtube-container iframe {
    height: 600px;
    width: 100%;
  }
  .hero-newsletter input {
    min-width: 405px;
  }
}

@media (max-width: 768px) {
  .partner-logos {
    justify-content: center;
    gap: 40px;
  }
  .hero-newsletter form {
    flex-wrap: wrap;
  }
  .partner-logos img {
    flex: 1 1 calc(50% - 20px);
  }

  .partner-logos span {
    display: none;
  }

  .hero-content h1 {
    font-size: 2.3rem;
    font-weight: 600;
  }
}

@media (max-width: 480px) {
  .partner-logos img {
    flex: 1 1 calc(100% - 20px);
  }

  .hero-content h1 {
    font-size: 2rem;
    font-weight: 600;
  }
}
