.logout-page h1 {
  text-align: center;
  background: var(--gradient-green-teal);
  padding: 1.25rem;
  border-radius: 0.5rem;
}

.logout-page {
  padding: 5rem 1.25rem;
}
